import * as React from 'react';
import styled from '@emotion/styled';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { secondaryDarker, secondaryMain, primaryMain, primaryLight } from '../../../constants/colors';
import { trialLandingPath } from '../../../constants/routes';

const Background = styled.div`
  background-image: url('/assets/landing/header-background-right.svg');
  background-repeat: no-repeat;
  background-position: 100% -10%;
  min-height: 700px;
`;

const Background2 = styled.div`
  background-image: url('/assets/landing/header-background-left.svg');
  background-repeat: no-repeat;
  background-position: top left;
  min-height: 700px;
`;

const ChartImage = styled.img`
  width: 100%;
  box-shadow: 0px 19.6981px 45.6165px rgba(212, 58, 101, 0.37), inset 0px 2.75054px 13.7527px rgba(255, 255, 255, 0.31);
  border-radius: 5px;
`;

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 500px;
`;

const BaseTypography = styled(Typography) `
  @media (max-width: 600px) {
    color: white;
  }
`;

const HeaderTypography = styled(BaseTypography)`
  font-size: 2.75rem;
  text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.38);
`;

const BodyTypography = styled(BaseTypography)`
  font-size: 1.25rem;
`;

const CommentTypography = styled(BaseTypography)`
  font-size: .75rem;
  margin: 0 25px;
`;

const DemoButton = styled(Button)`
  width: 230.8px;
  height: 67.22px;
  
  @media (min-width: 600px) {
    background: linear-gradient(180deg, #7F003E -2.58%, #8D0944 3.5%, #A91D51 18.7%, #C02C5B 34.92%, #D03762 52.15%, #D93D67 71.41%, #DC3F68 98.77%), #1C2945;
  }

  box-shadow: 0px 19.6981px 45.6165px rgba(212, 58, 101, 0.37), inset 0px 2.75054px 13.7527px rgba(255, 255, 255, 0.31);
  border-radius: 72.8246px;
  color: white;
  font-size: 21px;
  text-transform: none;
  float: left;
  @media (max-width: 600px) {
    background-color: white;
    color: ${secondaryDarker};
  }
`;

const FreeTrialButton = styled(Button)`
  width: 230.8px;
  height: 67.22px;
  
  @media (min-width: 600px) {
    background: linear-gradient(180deg, ${primaryMain}, #557dd4 98.77%);
  }

  box-shadow: 0px 19.6981px 45.6165px ${primaryLight}, inset 0px 2.75054px 13.7527px rgba(255, 255, 255, 0.31);
  border-radius: 72.8246px;
  color: white;
  font-size: 21px;
  text-transform: none;
  @media (max-width: 600px) {
    background-color: white;
    color: ${primaryMain};
  }
`;

const ButtonComments = styled.div`
  width: 100%;
  max-width: 550px;
  display: flex;
`;

const LeftButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RightButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Tagline = (props) => {
  return (
    <Background>
      <Background2>
        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Column component={Grid} item xs={12} sm={7}>
              <HeaderTypography variant='h3' marginBottom={2}>
                Driving Quality Through Innovative System Reliability Solutions
              </HeaderTypography>
              <BodyTypography variant='body1' marginBottom={3}>
                Introducing a new online, real-time service, for software quality assurance.
              </BodyTypography>
              <ButtonComments>
                <LeftButtonContainer>
                  <DemoButton href={`${process.env.REACT_APP_DASHBOARD_URL}/demo/mpm/executive-summary`}>Demo STAR Now</DemoButton>
                  <CommentTypography paddingTop={1} color={secondaryMain}>No payment required</CommentTypography>
                </LeftButtonContainer>
                <RightButtonContainer>
                  <FreeTrialButton href={`${trialLandingPath}`}>Free STAR Trial</FreeTrialButton>
                  <CommentTypography paddingTop={1} color={primaryMain}>2 Month Trial</CommentTypography>
                </RightButtonContainer>
              </ButtonComments>
            </Column>
            <Column component={Grid} item xs={12} sm={5} display={{ xs: 'none', sm: 'flex' }}>
              <ChartImage src={process.env.PUBLIC_URL + '/assets/landing/header-image.png'} alt='Chart Image' />
            </Column>
          </Grid>
        </Container>
      </Background2>
    </Background>
  ); 
}

export default Tagline;
